<script setup lang="ts">
import { clientStore } from "@/stores/client";
import { onMounted } from "vue";
import { initTheme } from '@/hooks/util'
import { setAllInOneMode } from "pages/allInOne/utils/hooks/adapter";
import { useThrottleFn, useDebounceFn } from "@vueuse/core";
import StartTips from './components/startTips/index.vue'
import { ref } from 'vue'
const client = clientStore()
// client.Login()
client.IntegrateLogin()
client.saveView('SESSION')
onMounted(() => {
  window.callback = function (auth: string) {
    const client = clientStore()
    client.oauthLoginCallback({ type: 'login', auth: auth })
  };
  initTheme()
  setAllInOneMode()
})
let timer = 0
const tipsVisible = ref(false)
const checkMouseAsleep = () => {
  tipsVisible.value = false
  timer = setTimeout(() => {
    tipsVisible.value = true
  }, 1000 * 60)
}

checkMouseAsleep()
const onUserOperate = () => {
  // console.log('用户操作')
  clearTimeout(timer)
  checkMouseAsleep()
}
</script>
<template>
  <div @click="onUserOperate">
    <RouterView />
    <StartTips :visible="tipsVisible" />
  </div>
</template>
<style>
html,
body {
  overflow: hidden;
}
</style>
