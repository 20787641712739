export const constantRoute = [
  {
    path: '/',
    name: 'dashboard_layout',
    component: () => import('../layout/studio/index.vue'),
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('../views/detail/index.vue'),
        name: 'dashboard_home',
        meta: { preload: true }
      },
      {
        path: '/design',
        component: () => import('../layout/design/index.vue'),
        name: 'design_layout',
        meta: { preload: true },
        redirect: '/design/index',
        children: [
          {
            path: '/design/index',
            component: () => import('pages/design/views/design/index.vue'),
            name: 'design_index',
            meta: { preload: true }
          },
          {
            path: '/design/allInOne', // 调整到自己的页面。c端不动
            component: () => import('../views/allInOne/index.vue'),
            name: 'allInOne',
            meta: { preload: true }
          },
          {
            path: '/design/all-in-one',
            component: () => import('pages/allInOne/views/home/index.vue'),
            name: 'all-in-one',
            meta: { preload: true }
          },
          {
            path: '/realTime',
            component: () => import('pages/design/views/realTime/index.vue'),
            name: 'realTime',
            meta: { preload: true }
          },
          {
            path: '/pending',
            component: () => import('pages/design/views/pending/index.vue'),
            name: 'pending',
            meta: { preload: true }
          },
          {
            path: '/result/:id',
            component: () => import('pages/design/views/result/index.vue'),
            name: 'result',
            meta: { preload: true }
          },
          {
            path: '/mine',
            component: () => import('../views/mine/index.vue'),
            name: 'mine',
            meta: { preload: true }
          }
        ]
      }
    ]
  },
  {
    path: '/chat',
    component: () => import('../views/chat/index.vue'),
    name: 'chat',
    meta: { preload: true }
  },
  {
    path: '/upload',
    component: () => import('../views/upload/index.vue'),
    name: 'upload',
    meta: { preload: true }
  }
]
