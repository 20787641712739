<template>
  <div class="start-tips-wrap" v-if="visible" @click="close">
    <!-- avi格式需要安装插件才能解析 -->
    <video ref="videoRef" :src="videoUrl" autoplay loop muted></video>
  </div>
</template>
<script setup lang="ts">
import videoUrl from '@/assets/videos/start-tips.mp4'
import {ref} from 'vue'
defineProps<{
  visible: boolean
}>()
const emits = defineEmits<{
  close: [boolean]
  'update:visible': [boolean]
}>()
const videoRef = ref()
const close = () => {
  emits('close', false)
  emits('update:visible', false)
}
</script>
<style scoped lang="less">
.start-tips-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6666;
  background: #000;
  display: flex;
  align-items: center;
  video{
    width: 100%;
  }
}
</style>